import React from 'react'
import { Link } from 'gatsby'
import facebook from '../img/social/facebook.svg'
import logo from '../img/logoRU.webp'
import home from '../img/home.png'
import pl from '../img/flags/pl.jpg'
import ru from '../img/flags/ru.jpg'
import en from '../img/flags/en.jpg'
import "./layout.css"
import { ThemeToggler } from "gatsby-plugin-dark-mode";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      activeMenu1: false,
      activeMenu2: false,
      activeMenu3: false,
      activeMenu4: false,
      buttonBlack: '',
      buttonBlack2: '',
      buttonBlack3: '',
      buttonBlack4: '',
      jezyki: '',
    }
  }

  jezykiOnClick = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        jezyki: !this.state.jezyki,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.jezyki
          ? this.setState({
            jezyki: 'jezykiDrop',
          })
          : this.setState({
            jezyki: '',
          })
      }
    )
  }
  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  MenuOnclick = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu1: !this.state.activeMenu1,

      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu1
          ? this.setState({
            activeItems: 'activeItems',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            activeMenu2: false,
            activeMenu3: false,
            activeMenu4: false,
            buttonBlack: 'buttonBlack',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick2 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu2: !this.state.activeMenu2,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu2
          ? this.setState({
            activeItems2: 'activeItems2',
            activeItems: '',
            activeItems3: '',
            activeItems4: '',
            activeMenu1: false,
            activeMenu3: false,
            activeMenu4: false,
            buttonBlack: '',
            buttonBlack2: 'buttonBlack',
            buttonBlack3: '',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick3 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu3: !this.state.activeMenu3,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu3
          ? this.setState({
            activeItems3: 'activeItems3',
            activeItems: '',
            activeItems2: '',
            activeItems4: '',
            activeMenu1: false,
            activeMenu2: false,
            activeMenu4: false,
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: 'buttonBlack',
            buttonBlack4: '',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }
  MenuOnclick4 = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        activeMenu4: !this.state.activeMenu4,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.activeMenu4
          ? this.setState({
            activeItems4: 'activeItems4',
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeMenu1: false,
            activeMenu2: false,
            activeMenu3: false,
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: 'buttonBlack',
          })
          : this.setState({
            activeItems: '',
            activeItems2: '',
            activeItems3: '',
            activeItems4: '',
            buttonBlack: '',
            buttonBlack2: '',
            buttonBlack3: '',
            buttonBlack4: '',
          })
      }
    )
  }

  render() {
    return (
      <div className="Navbar-Main">
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              {" "}
              <Link to="/indexRU" className="navbar-item" title="Home">
                <img alt=" " src={home} alt="Ekochron" />
              </Link>
              <div className="navbar-end has-text-centered flagiNav">
                <div
                  className="navbar-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.jezykiOnClick()}
                >
                  <span className="icon noselect flagaMain">
                    <img alt=" " src={ru} alt="Facebook" />
                    <p id="arrow">↓</p>
                  </span>
                </div>
                <div className={`dropdown-content ${this.state.jezyki}`}>
                  <ul className="Ul">
                    <a href="/">
                      PL{" "}
                      <img
                        alt=" "
                        src={pl}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                  <ul className="Ul">
                    <a href="/indexRU">
                      RU{" "}
                      <img
                        alt=" "
                        src={ru}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                  <ul className="Ul">
                    <a href="/indexENG">
                      EN{" "}
                      <img
                        alt=" "
                        src={en}
                        alt="Facebook"
                        className="FlagImage"
                      />
                    </a>
                  </ul>
                </div>
              </div>
              <ThemeToggler>
                {({ theme, toggleTheme }) => (
                  <label className="DarkModeLabel noselect" title="ночной режим">
                    <input
                      type="checkbox"
                      onChange={e =>
                        toggleTheme(e.target.checked ? "dark" : "light")
                      }
                      checked={theme === "dark"}
                    />{" "}
                    🌙
                  </label>
                )}
              </ThemeToggler>
              <div className="navbar-start has-text-centered" id="FirstNav">
                <Link
                  className="navbar-item klawisz"
                  to="/ocynkownia"
                  id="ocynkowniaItem"
                >
                  Цинковня
                </Link>
                <Link className="navbar-item klawisz" to="/jakosc/indexRU">
                  Качество
                </Link>
                <Link className="navbar-item klawisz" to="/poradniki/indexRU">
                  Справочник
                </Link>
                <Link className="navbar-item klawisz" to="/about/indexRU">
                  О нас
                </Link>
                <Link className="navbar-item klawisz" to="/blog/indexRU">
                  Новости
                </Link>
                <Link className="navbar-item klawisz" to="/instrukcje/indexRU">
                  Инструкции
                </Link>
                <Link className="navbar-item klawisz" to="/contact/indexRU">
                  Контакты
                </Link>
                <Link className="navbar-item klawisz" to="/certyfikaty/indexRU">
                  Сертификаты
                </Link>
              </div>
              <div className="navbar-end has-text-centered">
                <a
                  className="navbar-item"
                  href="https://www.facebook.com/ekochron/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img alt=" " src={facebook} alt="Facebook" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="container"></div>
        <div className="container">
          <div id="navMenu" className={`navbar-menu is-active`}>
            <div className="navbar-start has-text-centered" className="menu3">
              <div className="navbarMedium">
                <Link
                  to="/indexRU"
                  className="navbar-item"
                  title="Logo"
                  className="navbar3"
                >
                  <img
                    alt=" "
                    className="logoKlasa"
                    src={logo}
                    alt="Ekochron"
                  />
                </Link>
                <div className="szukajka">Szukajka</div>
                <div className="NavbarMediumMedium">
                  <p id="Contakt1">тел. +48 33 857 90 10</p>
                  <p id="Contakt1">+48 33 853 32 91</p>
                  <p id="Contakt2">понедельник – пятница: 7:00 - 15:00</p>
                  <a id="Contakt3" href="mailto:sprzedaz@ekochron.pl">
                    mail: sprzedaz@ekochron.pl
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu is-active`}>
            <div className="navbar-start has-text-centered" className="menu2">
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick()}
                  id="KomunikacjaDachowa"
                  className={`dropbtn ${this.state.buttonBlack}`}
                >
                  1. Коммуникационные пути на крыше ↓
                </button>
                <div
                  className={`dropdown-content lewy NavJeden ${this.state.activeItems}`}
                >
                  <dl>
                    <dt>
                      <a href="/komunikacjadachowa/lawakominiarskaRU">
                        1.1 Кровельный мостик
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/laczniklawkominiarskichRU">
                        1.2 Стыковочный элемент кровельных мостиков
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/stopienkominiarskiwaskiRU">
                        1.3 Ступень кровельная узкая
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/mocowniklawykominiarskiejRU">
                        1.4 Крепление кровельного мостика
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/przysciennymocowniklawRU">
                        1.5 Настенное крепление кровельного мостика
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniagietyRU">
                        1.6 Кронштейн мостика/ступени гнутый
                      </a>
                    </dt>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniagietypodklejonyRU">
                        1.6.1 Кронштейн мостика/ступени гнутый подклеенный
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniagietyzgumaepdmRU">
                        1.6.2 Кронштейн мостика/ступени гнутый с резиной EPDM
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopniakarpiowkajednoelementowyRU">
                        1.6.3 Кронштейн мостик/ступени типа «бобровый хвост»
                        (прямой) одноэлементный
                      </a>
                    </dd>
                    <dd>
                      <a href="/komunikacjadachowa/wsporniklawystopnianalupekRU">
                        1.6.4 Кронштейн мостика/ступени для сланца
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadlablachodachowekRU">
                        1.7 Кронштейн мостика/ступени для металлочерепицы
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniauniwersalnyRU">
                        1.8 Кронштейн мостика/ступени универсальный
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniakarpiowkizpodporkaRU">
                        1.9 Кронштейн мостика/ступени кровельный с подпоркой
                        (2-элементный)
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadachowkowytypcic1RU">
                        1.10 Кронштейн мостика/ступени кровельный с типа C и C1
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/wsporniklawystopniadlablachprofilowanychtypdecraRU">
                        1.11 Кронштейн мостика/ступени для профилированных
                        металлических листов типа DECRA
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/stabilizatorwspornikowlawystopniaRU">
                        1.12 Стабилизатор кронштейнов мостика/ступеней
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/barierkalawkominiarskichRU">
                        1.13 Перила кровельных мостиков
                      </a>
                    </dt>
                    <dt>
                      <a href="/komunikacjadachowa/drabinadachowaRU">
                        1.14 Кровельная лестница
                      </a>
                    </dt>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick2()}
                  id="OchronaPrzeciwsniegowa"
                  className={`dropbtn ${this.state.buttonBlack2}`}
                >
                  2. Защита от снега ↓
                </button>
                <div
                  className={`dropdown-content lewy NavDwa ${this.state.activeItems2}`}
                >
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/plotkiprzeciwsniegowe20i15RU">
                        2.1 Решетчатые снегозадержатели 20 и 15 см
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/lacznikplotkaprzeciwsniegowegoRU">
                        2.1.1 Стыковочный элемент решетчатого снегозадержателя
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypaRU">
                        2.1.2 Кронштейн решетчатого снегозадержателя для
                        черепицы типа «бобровый хвост»
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlakarpiowki">
                        2.1.3 Wspornik płotka dla karpiówki
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypbnRU">
                        2.1.4 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ ДЛЯ
                        ЧЕРЕПИЦЫ ТИПА ВN
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlablachodachowek">
                        2.1.7 Wspornik płotka dla blachodachówek
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadlablachdecraRU">
                        2.1.6 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ ДЛЯ
                        ПРОФИЛИРОВАННЫХ МЕТАЛЛИЧЕСКИХ ЛИСТОВ ТИПА DECRA
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkauniwersalnytypaRU">
                        2.1.7 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ
                        УНИВЕРСАЛЬНЫЙ ТИПА А
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkauniwersalnytypbRU">
                        2.1.8 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ
                        УНИВЕРСАЛЬНЫЙ ТИПА В
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkanalupekRU">
                        2.1.9 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ ДЛЯ СЛАНЦА
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikplotkadachowkowytypcic1RU">
                        2.1.10 КРОНШТЕЙН РЕШЕТЧАТОГО СНЕГОЗАДЕРЖАТЕЛЯ ДЛЯ
                        ЧЕРЕПИЦЫ ТИПА С И С1
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/belkidrewnianeRU">
                        2.3. БАЛКИ ДЕРЕВЯННЫЕ Ø120
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidachowkowytypaRU">
                        2.3.1 КРОНШТЕЙН БАЛКИ ДЛЯ ЧЕРЕПИЦЫ ТИПА А
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidachowkowytypbRU">
                        2.3.2 КРОНШТЕЙН БАЛКИ ДЛЯ ЧЕРЕПИЦЫ ТИПА В
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkikarpiowkiRU">
                        2.3.3 КРОНШТЕЙН БАЛКИ ДЛЯ ЧЕРЕПИЦЫ ТИПА «БОБРОВЫЙ ХВОСТ»
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkidlablachodachowekRU">
                        2.3.4 КРОНШТЕЙН БАЛКИ ДЛЯ МЕТАЛЛОЧЕРЕПИЦЫ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikbelkiuniwersalnyRU">
                        2.3.5 КРОНШТЕЙН БАЛКИ УНИВЕРСАЛЬНЫЙ
                      </a>
                    </dd>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/ruryprzeciwniegoweRU">
                        2.2 ТРУБЧАТЫЕ СНЕГОЗАДЕРЖАТЕЛИ Ø32MM
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypaRU">
                        2.2.1 КРОНШТЕЙН ТРУБ ДЛЯ ЧЕРЕПИЦЫ ТИПА А
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypbRU">
                        2.2.2 КРОНШТЕЙН ТРУБ ДЛЯ ЧЕРЕПИЦЫ ТИПА В
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdachowkowytypcic1RU">
                        2.2.3 КРОНШТЕЙН ТРУБ ДЛЯ ЧЕРЕПИЦЫ ТИПА C И C1
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdlakarpiowkiRU">
                        2.2.4 КРОНШТЕЙН СНЕГОЗАДЕРЖАТЕЛЯ ДЛЯ ЧЕРЕПИЦЫ ТИПА «БОБРОВЫЙ ХВОСТ»
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurdlablachodachowekRU">
                        2.2.5 КРОНШТЕЙН ТРУБ ДЛЯ МЕТАЛЛОЧЕРЕПИЦЫ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrurnalupekRU">
                        2.2.6 КРОНШТЕЙН ТРУБ ДЛЯ СЛАНЦА
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikruruniwersalnyRU">
                        2.2.7 КРОНШТЕЙН ТРУБ УНИВЕРСАЛЬНЫЙ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/lacznikrurRU">
                        2.2.8 СТЫКОВОЧНЫЕ ЭЛЕМЕНТЫ ТРУБ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/korekzaslepkarurRU">
                        2.2.9 ПРОБКА/ЗАГЛУШКА ДЛЯ ТРУБ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/wspornikrelingowyrurprzeciwsniegowych">
                        2.2.10 Wspornik relingowy rur przeciwśniegowych
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczesnieguisniegolapyRU">
                        2.4. СНЕГОРЕЗЫ И СНЕГОУЛОВИТЕЛИ
                      </a>
                    </dt>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudachowkowyRU">
                        2.4.1 СНЕГОРЕЗ ДЛЯ ЧЕРЕПИЦЫ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudlakarpiowkiRU">
                        2.4.2 СНЕГОРЕЗ ДЛЯ ЧЕРЕПИЦЫ ТИПА «БОБРОВЫЙ ХВОСТ»
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegudlablachodachowekRU">
                        2.4.3 СНЕГОРЕЗ ДЛЯ МЕТАЛЛОЧЕРЕПИЦЫ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsnieguprostyRU">
                        2.4.4 СНЕГОРЕЗ ПРЯМОЙ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegustoperdachowyRU">
                        2.4.5 СНЕГОРЕЗ – СТОППЕР ДЛЯ ЧЕРЕПИЦЫ
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/rozbijaczsniegustopernagnotRU">
                        2.4.6 СНЕГОРЕЗ – СТОППЕР ДЛЯ ГОНТА
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/sniegolaplopatkaRU">
                        2.4.7 СНЕГОУЛОВИТЕЛЬ «ЛОПАТКА»
                      </a>
                    </dd>
                    <dd>
                      <a href="/ochronaprzeciwsniegowa/sniegolapkrzyzslowackiRU">
                        2.4.8 СНЕГОУЛОВИТЕЛЬ «СЛОВАЦКИЙ КРЕСТ»
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick3()}
                  id="DachoweHaki"
                  className={`dropbtn ${this.state.buttonBlack3}`}
                >
                  3. Кровельные защитные крюки типа а ↓
                </button>
                <div
                  className={`dropdown-content prawy NavTrzy ${this.state.activeItems3}`}
                >
                  <dl>
                    <dt>
                    </dt>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacydachowkowytypaRU">
                        3.1 КРОВЕЛЬНЫЕ СТРАХОВОЧНЫЕ КРЮКИ ТИПА А
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacydachowkowytypbRU">
                        3.2 КРОВЕЛЬНЫЙ СТРАХОВОЧНЫЙ КРЮК ТИПА В
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowkowyhakzabezpieczajacydlablachodachowekmod35RU">
                        3.3 КРОВЕЛЬНЫЙ СТРАХОВОЧНЫЙ КРЮК ДЛЯ МЕТАЛЛОЧЕРЕПИЦЫ МОД. 35
                      </a>
                    </dd>
                    <dd>
                      <a href="/dachowehakizabezpieczajacetypa/dachowyhakzabezpieczajacyuniwersalnydopokrycplaskichRU">
                        3.4 КРОВЕЛЬНЫЙ СТРАХОВОЧНЫЙ КРЮК УНИВЕРСАЛЬНЫЙ ДЛЯ ПЛОСКОЙ КРОВЛИ
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="dropdown">
                <button
                  onClick={() => this.MenuOnclick4()}
                  id="PozostaleWyroby"
                  className={`dropbtn ${this.state.buttonBlack4}`}
                >
                  4. Прочие изделия ↓
                </button>
                <div
                  className={`dropdown-content prawy NavCztery ${this.state.activeItems4}`}
                >
                  <dl>
                    <dt>
                      <a href="/pozostalewyroby/uchwytnarabekstojacyRU">
                        4.1 ДЕРЖАТЕЛЬ ДЛЯ СТОЯЧЕГО ФАЛЬЦА
                      </a>
                    </dt>
                    <dt>
                      <a href="/pozostalewyroby/antyptakkominowyRU">
                        4.2 СИСТЕМА «АНТИПТИЦА» ДЛЯ ДЫМОХОДА
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkominowykwadratowyRU">
                        4.2.1 СИСТЕМА «АНТИПТИЦА» ДЛЯ ДЫМОХОДА КВАДРАТНАЯ
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkominowyprostokatnyRU">
                        4.2.2 СИСТЕМА «АНТИПТИЦА» ДЛЯ ДЫМОХОДА ПРЯМОУГОЛЬНАЯ
                      </a>
                    </dd>
                    <dt>
                      <a href="/pozostalewyroby/antyptakkolcowyRU">
                        4.3 СИСТЕМА «АНТИПТИЦА» С ШИПАМИ
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypaRU">
                        4.3.1 СИСТЕМА «АНТИПТИЦА» С ШИПАМИ ТИПА А
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypbRU">
                        4.3.2 СИСТЕМА «АНТИПТИЦА» С ШИПАМИ ТИПА В
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/antyptakkolcowytypcRU">
                        4.3.3 СИСТЕМА «АНТИПТИЦА» С ШИПАМИ ТИПА A
                      </a>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <a href="/pozostalewyroby/uchwytsolaraRU">
                        4.4 ДЕРЖАТЕЛЬ СОЛНЕЧНОЙ БАТАРЕИ
                      </a>
                    </dt>
                    <dd>
                      <a href="/pozostalewyroby/uchwytsolaradachowkowytypbRU">
                        4.4.1 ДЕРЖАТЕЛЬ СОЛНЕЧНОЙ БАТАРЕИ ТИП В
                      </a>
                    </dd>
                    <dd>
                      <a href="/pozostalewyroby/uchwytsolaraplaskiRU">
                        4.4.2 ДЕРЖАТЕЛЬ СОЛНЕЧНОЙ БАТАРЕИ ПЛОСКИЙ
                      </a>
                    </dd>
                    <dt>
                      <a href="/pozostalewyroby/laczniklatdachowkowychRU">
                        4.7 СТЫКОВОЧНЫЙ ЭЛЕМЕНТ КРОВЕЛЬНЫХ ОБРЕШЕТИН
                      </a>
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar
